<template>
  <div class="modal">
    <div class="modal__container">
      <!-- MODAL -->
      <div class="city-modal">
        <h3 class="city-modal__title">Ваш город Алматы?</h3>
        <div class="city-modal__buttons">
          <button
            class="city-modal__btn city-modal__btn-red"
            @click="isAlmatyCity"
          >
            Да
          </button>
          <button class="city-modal__btn" @click="isAnotherCity">Нет</button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapState, mapActions } from "vuex";
import { actionTypes } from "@/store";
import { actionTypes as actionTypesCity } from "@/store/modules/city";

export default {
  name: "AppModalCity",
  computed: {
    ...mapState("cityModule", { cities: "data" }),
  },
  methods: {
    ...mapActions({
      openSelectCity: actionTypes.changeIsOpenSelectCity,
    }),
    ...mapActions("cityModule", {
      saveChoosedCity: actionTypesCity.changeSelectedCity,
    }),
    isAlmatyCity() {
      this.$emit("close");
      this.saveCity(this.cities.find((e) => e.attributes.name === "Алматы"));
    },
    isAnotherCity() {
      this.$emit("close");
      this.openSelectCity();
    },
    saveCity(city) {
      this.saveChoosedCity(city);
      this.$router.push({
        name: this.$route.name,
        params: { ...this.$route.params },
        query: { ...this.$route.query, city_id: city.id || 2 },
      });
    },
  },
};
</script>
